<template>
  <b-overlay :show="isLoading">

    <!--        Details-->
    <div class="row">
      <div class="col-sm-12 row">
                <div class="form-group col-sm">
                    <label>libelle </label>
                    <input v-model="form.programmations.libelle"
                           :class="errors.libelle?'form-control is-invalid':'form-control'" disabled
                           type="text">

                    <div v-if="errors.libelle" class="invalid-feedback">
                        <template v-for=" error in errors.libelle"> {{ error[0] }}</template>

                    </div>
                </div>
                <div class="form-group col-sm">
                    <label >date </label>
                    <input v-model="DateListing"
                           :class="errors.date_debut ? 'form-control is-invalid' : 'form-control'" disabled type="date">
                    <div v-if="errors.date_debut" class="invalid-feedback">
                        <template v-for=" error in errors.date_debut"> {{ error[0] }}</template>

                    </div>
                </div>
                <div class="form-group col-sm">
                    <label >valider 1 </label>
                    <input v-model="form.valider1"
                           :class="errors.valider1 ? 'form-control is-invalid' : 'form-control'" disabled type="datetime">
                    <div v-if="errors.valider1" class="invalid-feedback">
                        <template v-for=" error in errors.valider1"> {{ error[0] }}</template>

                    </div>
                </div>
                <div class="form-group col-sm">
                    <label >valideur 1</label>
                    <CustomSelect :key="form.user" :columnDefs="['nom', 'prenom', 'matricule']" :disable="true"
                                  :oldValue="form.user" :renderCallBack="(data) => `${data.nom}`"
                                  :selectCallBack="(data) => form.user = data.id"
                                  :url="`${axios.defaults.baseURL}/api/users-Aggrid1`" filter-key="" filter-value=""
                                  placeHolder=" Pas encore valider "/>
                    <div v-if="errors.valideur_1" class="invalid-feedback">
                        <template v-for=" error in errors.valideur_1"> {{ error[0] }}</template>
                    </div>
                    <small class="form-text text-muted">{{ potentielValideur1() }}</small>
                </div>

                <div class="form-group col-sm">
                    <button v-if="$routeData.meta.validation1 ||$routeData.meta.validation2"
                            :disabled="form.valider1.length>0"
                            class="mt-2 btn btn-primary " type="button"
                            @click.prevent="Validation1()"
                    >
                        <i class="fa-solid fa-circle-check"></i>
                        {{ valider1Text }}
                    </button>

                </div>
                <div class="form-group col-sm">
                    <label >valider 2 </label>
                    <input v-model="form.valider2"
                           :class="errors.valider1 ? 'form-control is-invalid' : 'form-control'" disabled type="datetime">
                    <div v-if="errors.valider2" class="invalid-feedback">
                        <template v-for=" error in errors.valider2"> {{ error[0] }}</template>

                    </div>
                </div>
                <div class="form-group col-sm">
                    <label>valideur 2</label>
                    <CustomSelect :key="form.user2" :columnDefs="['nom', 'prenom', 'matricule']" :disable="true"
                                  :oldValue="form.user2" :renderCallBack="(data) => `${data.nom}`"
                                  :selectCallBack="(data) => form.user2 = data.id"
                                  :url="`${axios.defaults.baseURL}/api/users-Aggrid1`" filter-key="" filter-value=""
                                  placeHolder=" Pas encore valider "/>
                    <div v-if="errors.valideur_2" class="invalid-feedback">
                        <template v-for=" error in errors.valideur_2"> {{ error[0] }}</template>
                    </div>

                    <small class="form-text text-muted">{{ potentielValideur2() }}</small>
                </div>
                <div class="form-group col-sm">
                    <button v-if="$routeData.meta.validation1 || $routeData.meta.validation2"
                            :disabled="form.valider2.length>0 || form.valider1.length<=0"
                            class="mt-2 btn btn-primary " type="button"
                            @click.prevent="Validation2()"
                    >
                        <i class="fa-solid fa-circle-check"></i>
                        {{ valider2Text }}
                    </button>
                </div>
<!--                <div class="form-group col-sm-2">-->
<!--                    <button class="mt-2 btn btn-primary " type="button" @click.prevent="launchImpression()">-->
<!--                        <i class="fa-solid fa-print"></i>-->

<!--                    </button>-->
<!--                    <button class="btn btn-primary mt-2" type="button" @click="EditLine()">-->
<!--                        <i class="fas fa-floppy-disk"></i>-->
<!--                    </button>-->
<!--                    <button class="mt-2 btn btn-danger " type="button" @click.prevent="DeleteLine()">-->
<!--                        <i class="fas fa-close"></i>-->
<!--                    </button>-->
<!--                </div>-->



            </div>

      <div class="col-sm-12">
        <template>
          <div v-if="periodes.length > 1">

            <h5>Veuillez selectionnez le jour</h5>
            <div style="display: flex;justify-content: space-around;">
              <button v-for="date in periodes"
                      :style="(periodesSelectionner == date) ? 'border:1px solid green' : 'border:1px solid #b3b2b2'"
                      class="btn" @click="selectPeriode(date)">{{ date }}
              </button>

            </div>

          </div>
          <Readprogrammes
              :key="form.id + '-' + tableKey + '-' + periodesSelectionner" :data="form" :filter="filter"
              :horairesData="horairesData" :periode="periodesSelectionner" :poste="{id:0}"
              :types="$route.meta.isProgrammations" :usersData="usersData"/>

        </template>
      </div>

    </div>

  </b-overlay>
</template>

<script>

import * as htmlToImage from 'html-to-image';
import moment from "moment/moment";

export default {
  name: 'EditProgrammations',
  components: {
    VSelect: () => import("vue-select"), CustomSelect: () => import("@/components/CustomSelect.vue"),
    Files: () => import("@/components/Files.vue"),
    Managesprogrammesusers: () => import("./Managesprogrammesusers.vue"),
    Readprogrammes: () => import("./Readprogrammes.vue"),
    ReadProgrammesUsersListings: () => import("./ReadProgrammesUsersListings.vue"),
    StatisticCardHorizontal: () => import("@core/components/statistics-cards/StatisticCardHorizontal.vue"),
    ClientsView: () => import("./Clients/ClientsView.vue"),
  },
  props: ['data', 'gridApi', 'modalFormId',
    'tachesData',
    'usersData',
  ],
  data() {
    return {
      errors: [],
      validationsData: [],
      isLoading: false,
      form: {
        id: "",
        libelle: "",
        description: "",
        date_debut: "",
        date_fin: "",
        tache_id: "",
        user_id: "",
        statut: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        identifiants_sadge: "",
        PresentsId: [],
        AbscentsId: [],
        Allclients: [],
        Allclients1: [],
      },
      horairesData: [],
      DateListing: null,
      filter: false,
      tableKey: 0,
      clients: '',
      clientsSelectioner: [],
      actualPeriode: '',
    }
  },
  computed: {


    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    valider1Text: function () {
      let text = " 1er etape de validation"
      if (this.form.type != 'programmations') {
        text = 'Valider 1'
      }
      return text
    },
    valider2Text: function () {
      let text = " 2eme etape de validation"

      if (this.form.type != 'programmations') {
        text = 'Valider 2'
      }
      return text

    },
    clientsData: function () {
      let data = [];
      try {
        data = this.form.Allclients.map(e => {
          return {
            'id': e.clientId,
            'Selectlabel': e.clientLibelle,
          }
        })
        data = [...new Map(data.map(item => [item['id'], item])).values()]
      } catch (e) {

      }
      console.log('voici les clients ', data)
      return data;
    },
    postesData: function () {
      let countClient = 0;
      try {
        countClient = this.form.Allclients.length
      } catch (e) {
        countClient = 0;
      }
      let data = [];
      try {
        data = this.form.Allclients.filter(e => this.clientsSelectioner.includes(e.clientId)).map(e => {
          return {
            'id': e.posteId,
            'Selectlabel': e.posteLibelle,
            'horaireId': e.horaireId,
          }
        })
        data = [...new Map(data.map(item => [item['id'], item])).values()]
      } catch (e) {

      }

      if (data.length == 0 && countClient == 0) {
        data.push({id: 0})
      }
      return data;
    },
    periodes: function () {
      let dateA = moment(this.data.date_debut, 'YYYY-MM-DD')
      let dateB = moment(this.data.date_fin, 'YYYY-MM-DD')
      let date = [dateA.format('YYYY-MM-DD')]
      let encore = true
      let i = 0;
      let diff = dateB.diff(dateA, 'days');
      if (diff > 0) {
        while (encore) {
          let actual = date[date.length - 1]
          actual = moment(actual, 'YYYY-MM-DD')
          let demain = actual.add(1, 'days')
          console.log('voici la date  actual ==>', actual, demain.format('YYYY-MM-DD'))
          date.push(demain.format('YYYY-MM-DD'))
          if (dateB.diff(actual, 'days') == 0 || i == 30) {
            encore = false
          } else {

            i++
          }

        }
      }

      console.log('voici la date ==>', dateA, dateB, this.data, this.data.date_fin)
      console.log('voici la dates collecter ==>', date, i)
      return date;

    },
    periodesSelectionner: function () {

      let resultat = this.actualPeriode;
      if (resultat == '') {
        resultat = this.periodes[0]
      }
      return resultat;
    }
  },
  watch: {
    'filter': {
      handler: function (after, before) {
        this.tableKey++

      },
      deep: true

    }
  },

  mounted() {
    this.form = this.data
    this.DateListing = this.form.date_debut.split(' ')[0]
    console.log('this.DateListing', this.DateListing, this.form.date_debut);
    this.form.Allclients = []
    this.form.Allclients1 = []
    this.form['date_debut'] = this.form['date_debut'].split(' ')[0]
    this.form['date_fin'] = this.form['date_fin'].split(' ')[0]
    this.analyseData()
    this.validationsData = ["client", "interne", "operationnel"]

    this.gethoraires()
    // this.count()
  },

  methods: {
    selectPeriode(date) {
      this.actualPeriode = date
    },
    potentielValideur1() {
      let allName = []
      try {
        allName.push(this.form.user.Selectlabel)
        allName.push(this.form.user2.Selectlabel)
      } catch (e) {

      }
      return allName.join(' ou ')

    },
    potentielValideur2() {
      let allName = []
      try {
        allName.push(this.form.user3.Selectlabel)
        allName.push(this.form.user4.Selectlabel)
      } catch (e) {

      }
      return allName.join(' ou ')
    },
    analyseData() {
      this.isLoading = true
      let dataFilters = {
        "startRow": 0,
        "endRow": 100,
        "rowGroupCols": [],
        "valueCols": [],
        "pivotCols": [],
        "pivotMode": false,
        "groupKeys": [],
        "filterModel": {},
        "sortModel": [],
        'id': [this.form.id],
      }
      let that = this
      this.axios.post('/api/programmations-Aggrid1', dataFilters).then(response => {
        that.form = response.data.rowData[0]
        console.log('recu', that.form)
        that.form.Allclients = JSON.parse(that.form.Allclients)
        that.form.Allclients1 = Object.keys(that.form.Allclients)
        that.form['date_debut'] = that.form['date_debut'].split(' ')[0]
        that.form['date_fin'] = that.form['date_fin'].split(' ')[0]

        console.log('recu 1', that.form)
        that.isLoading = false
      }).catch(error => {
        that.isLoading = false
      })
    },
    selectClient(clientId) {
      if (this.clientsSelectioner.includes(clientId)) {
        this.clientsSelectioner = this.clientsSelectioner.filter(e => e != clientId)
      } else {
        this.clientsSelectioner.push(clientId)
      }
    },
    imprimer() {
      let that = this

      function filter(node) {
        return (node.tagName !== 'img');
      }

      let node = document.getElementById(`listing${this.form.id}`);
      htmlToImage.toJpeg(node, {filter: filter})
          .then(function (dataUrl) {

            let win = window.open('');
            win.document.write('<img src="' + dataUrl + '" onload="window.print();window.close()" />');
            win.focus();

          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });


    },
    Validation1() {
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + ' ' + time;
      // console.log('clikk=>', dateTime)
      this.form.valider1 = dateTime
      // console.log('this.form', this.form)
      this.isLoading = true
      this.axios.post('/api/programmations/' + this.form.id + '/update', this.form).then(response => {
        this.isLoading = false
        this.gridApi.applyServerSideTransaction({
          update: [
            response.data
          ],
        });
        this.$bvModal.hide(this.modalFormId)
        this.$emit('close')
        this.$toast.success('Operation effectuer avec succes')
        // console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    Validation2() {
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + ' ' + time;
      // console.log('clikk=>', dateTime)
      this.form.valider2 = dateTime
      // console.log('this.form', this.form)
      this.isLoading = true
      this.axios.post('/api/programmations/' + this.form.id + '/update', this.form).then(response => {
        this.isLoading = false
        this.gridApi.applyServerSideTransaction({
          update: [
            response.data
          ],
        });
        this.$bvModal.hide(this.modalFormId)
        this.$emit('close')
        this.$toast.success('Operation effectuer avec succes')
        // console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    launchImpression() {
      if (this.form.type != "programmations") {
        this.Imprimer()
      } else {
        this.ImprimerProgrammation()
      }

    },
    Imprimer() {
      this.isLoading = true
      this.axios.post('/api/imprimme-listing', this.form).then(response => {
        this.isLoading = false
        window.open(response.data, "_blank");
        this.$toast.success('Operation effectuer avec succes')
      }).catch(error => {
        this.errors = error
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    ImprimerProgrammation() {
      this.isLoading = true;
      this.axios
          .post("/api/imprimme-Programmation", this.form)
          .then((response) => {
            this.isLoading = false;
            window.open(response.data, "_blank");
            this.$toast.success("Operation effectuer avec succes");
          })
          .catch((error) => {
            this.errors = error;
            this.isLoading = false;
            this.$toast.error(
                "Erreur survenue lors de l'enregistrement"
            );
          });
    },
    launchPrint(url) {
      var iframe = this._printIframe;
      if (!this._printIframe) {
        iframe = this._printIframe = document.createElement('iframe');
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      }

      iframe.src = url;
    },
    closeForm() {
      this.$bvModal.hide(this.modalFormId)
    },
    EditLine() {
      this.isLoading = true
      this.axios.post('/api/programmations/' + this.form.id + '/update', this.form).then(response => {
        this.isLoading = false
        this.gridApi.applyServerSideTransaction({
          update: [
            response.data
          ],
        });
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    ValideLine() {
      this.isLoading = true
      this.axios.post('/api/programmations/' + this.form.id + '/update', {statut: 'valider'}).then(response => {
        this.isLoading = false
        this.gridApi.applyServerSideTransaction({
          update: [
            response.data
          ],
        });
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    DeleteLine() {
      this.isLoading = true
      this.axios.post('/api/programmations/' + this.form.id + '/delete').then(response => {
        this.isLoading = false

        this.gridApi.applyServerSideTransaction({
          remove: [
            this.form
          ]
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        console.log(error.response.data)
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de la suppression')
      })
    },
    DuplicateLine() {

    },
    gethoraires() {
      let filter = {
        "startRow": 0,
        "endRow": 100,
        "rowGroupCols": [],
        "valueCols": [],
        "pivotCols": [],
        "pivotMode": false,
        "groupKeys": [],
        "filterModel": {
          "parent": {"filterType": "text", "type": "contains", "filter": "tache"},
          "parentId": {"filterType": "text", "type": "contains", "filter": this.data.tache_id}
        },
        "sortModel": []
      }
      this.axios.post('/api/horaires-Aggrid1', filter).then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.horairesData = response.data['rowData']
        console.log('=>horairesData', this.horairesData)

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        // this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },
    checkAbscents() {

      if (!this.filter) {
        this.filter = this.form.Abscentsid
      } else {
        this.filter = false
      }
    },
    checkPresents() {
      if (!this.filter) {
        this.filter = this.form.Presentsid
      } else {
        this.filter = false
      }
    },
    count() {
      this.axios.get('/api/programmations/id/' + this.form.id).then((response) => {
        this.requette--
        if (this.requette == 0) {
          this.$store.commit('setIsLoading', false)
        }

        //    console.log('okkkk',response.data[0].Abscents)
        this.Presents = response.data[0].Presents
        this.Abscents = response.data[0].Abscents


      }).catch(error => {
        console.log(error.response.data)
        this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
      // alert(this.Presents)
    }
  }
}
</script>
<style>
.btn-non-selectionner {
  border: 1px solid #b5b5b5;
  width: 100%
}

.btn-selectionner {
  border: 1px solid #24a207;
  width: 100%
}

#allClientsInListings {
  max-height: 250px;
  overflow-x: scroll;
}
</style>
